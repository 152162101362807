var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "coupon_content" },
    [
      _vm.storeList && _vm.storeList.length > 0
        ? _c(
            "div",
            { staticClass: "store_content" },
            [
              _c("span", { staticClass: "store_name" }, [
                _vm._v("请先选择店铺 : "),
              ]),
              _c(
                "el-select",
                {
                  attrs: { size: "mini", placeholder: "请先选择店铺" },
                  on: { change: _vm.changeStore },
                  model: {
                    value: _vm.storeId,
                    callback: function ($$v) {
                      _vm.storeId = $$v
                    },
                    expression: "storeId",
                  },
                },
                _vm._l(_vm.storeList, function (item) {
                  return _c("el-option", {
                    key: item.storeId,
                    attrs: { label: item.storeName, value: item.storeId },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "search-panel",
        {
          attrs: { flex: "", "show-btn": "" },
          on: { getList: _vm.getListChange },
        },
        [
          _c(
            "div",
            [
              _c("p", [_vm._v("优惠券名称/ID:")]),
              _c("el-input", {
                attrs: {
                  placeholder: "请输入优惠券名称/ID",
                  size: "mini",
                  clearable: "",
                },
                model: {
                  value: _vm.searchParams.couponName,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.searchParams,
                      "couponName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "searchParams.couponName",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("p", [_vm._v("优惠券类型:")]),
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择优惠券类型",
                    size: "mini",
                    clearable: "",
                  },
                  model: {
                    value: _vm.searchParams.couponSubType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "couponSubType", $$v)
                    },
                    expression: "searchParams.couponSubType",
                  },
                },
                _vm._l(_vm.subCouponList, function (item) {
                  return _c("el-option", {
                    key: item.typeId,
                    attrs: { label: item.typeName, value: item.typeId },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("p", [_vm._v("活动状态:")]),
              _c(
                "el-select",
                {
                  attrs: {
                    size: "mini",
                    placeholder: "请选择状态",
                    clearable: "",
                  },
                  model: {
                    value: _vm.searchParams.activiteState,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "activiteState", $$v)
                    },
                    expression: "searchParams.activiteState",
                  },
                },
                [
                  _c("el-option", { attrs: { value: 0, label: "未开始" } }),
                  _c("el-option", { attrs: { value: 1, label: "生效中" } }),
                  _c("el-option", { attrs: { value: 2, label: "已结束" } }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            "element-loading-text": "拼命加载中",
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "couponId", label: "优惠券ID", "min-width": "110" },
          }),
          _c("el-table-column", {
            attrs: { prop: "couponName", label: "券名称", "min-width": "110" },
          }),
          _c("el-table-column", {
            attrs: { prop: "discountsValue", label: "面额/折扣", width: "110" },
          }),
          _c("el-table-column", {
            attrs: { label: "领用条件", "min-width": "110" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.couponSubType === 204
                      ? _c("div", [_vm._v("/")])
                      : _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.discountsType &&
                                  _vm.discountsType[scope.row.discountsType]
                              ) +
                              " " +
                              _vm._s(
                                _vm.discountsConditionType &&
                                  _vm.discountsConditionType[
                                    scope.row.discountsConditionType
                                  ]
                              ) +
                              " " +
                              _vm._s(scope.row.discountsCondition) +
                              " 元可用 "
                          ),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "couponTotalCount",
              label: "发放数量",
              width: "110",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "有效时间", "min-width": "160" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.dayFormat(scope.row.validStartTime)) +
                        " - " +
                        _vm._s(_vm.dayFormat(scope.row.validEndTime)) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "活动状态", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.comState(scope.row.activiteState)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              "min-width": "180",
              align: "center",
              fixed: "right",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.toEdit(scope.row, "show")
                          },
                        },
                      },
                      [_vm._v(" 查看 ")]
                    ),
                    _c("el-divider", { attrs: { direction: "vertical" } }),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: scope.row.activiteState !== 0,
                          type: "text",
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.toEdit(scope.row, "edit")
                          },
                        },
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                    _c("el-divider", { attrs: { direction: "vertical" } }),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled:
                            scope.row.activiteState === 2 ||
                            scope.row.activiteState === 3,
                          type: "text",
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.setCouponStatus(scope.row.couponId, 1)
                          },
                        },
                      },
                      [_vm._v(" 下架 ")]
                    ),
                    _c("el-divider", { attrs: { direction: "vertical" } }),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: scope.row.activiteState !== 3,
                          type: "text",
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.setCouponStatus(scope.row.couponId, 0)
                          },
                        },
                      },
                      [_vm._v(" 上架 ")]
                    ),
                    _c("el-divider", { attrs: { direction: "vertical" } }),
                    scope.row.couponSubType !== 204
                      ? [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                disabled: scope.row.activiteState === 2,
                                type: "text",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.toAddNum(scope.row)
                                },
                              },
                            },
                            [_vm._v(" 增发 ")]
                          ),
                          _c("el-divider", {
                            attrs: { direction: "vertical" },
                          }),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.onShowPop(scope.row)
                                },
                              },
                            },
                            [_vm._v(" 推广 ")]
                          ),
                        ]
                      : _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.downloadRedemptionCode(scope.row)
                              },
                            },
                          },
                          [_vm._v(" 下载兑换码 ")]
                        ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          small: "",
          "current-page": _vm.pagination.page,
          "page-sizes": [10, 20, 30, 50],
          "page-size": _vm.pagination.page_count,
          layout: "prev, pager, next",
          total: _vm.pagination.item_total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _vm.circulationVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "增加优惠券发行量",
                visible: _vm.circulationVisible,
                width: "30%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.circulationVisible = $event
                },
              },
            },
            [
              _c("div", { staticClass: "margin" }, [
                _vm._v(" 当前发行量: " + _vm._s(_vm.currentNum) + " 张 "),
              ]),
              _c("span", [_vm._v("新增发行量: ")]),
              _c("el-input-number", {
                staticStyle: { width: "200px" },
                attrs: { min: 0, size: "mini" },
                model: {
                  value: _vm.addNum,
                  callback: function ($$v) {
                    _vm.addNum = $$v
                  },
                  expression: "addNum",
                },
              }),
              _vm._v(" 张 "),
              _c("div", { staticClass: "margin" }, [
                _vm._v(" 增加后发行量: " + _vm._s(_vm.sum) + " 张 "),
              ]),
              _c("div", { staticClass: "warning margin" }, [
                _vm._v(" 总发行量不可超过1000000 "),
              ]),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.onSureAddCount },
                    },
                    [_vm._v("确 定")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          _vm.circulationVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("Pop", {
        attrs: {
          show: _vm.showPop,
          id: _vm.popId,
          couponType: "2",
          tokenType: "1",
        },
        on: {
          "update:show": function ($event) {
            _vm.showPop = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }