<template>
  <div class="over_view">
    <div
      v-if="storeList && storeList.length > 0"
      class="store_content"
    >
      <span class="store_name">请先选择店铺 : </span>
      <el-select
        v-model="storeId"
        size="mini"
        placeholder="请先选择店铺"
        @change="changeStore"
      >
        <el-option
          v-for="item in storeList"
          :key="item.storeId"
          :label="item.storeName"
          :value="item.storeId"
        >
        </el-option>
      </el-select>
    </div>
    <h2>营销效果</h2>
    <div class="underline"></div>
    <ul class="result">
      <li>
        <div class="left_icon">
          <i class="el-icon-user-solid"></i>
        </div>
        <div class="right_content">
          <div class="title">
            累计支付订单数
          </div>
          <div class="num">
            {{ overViewResult.orderCount }}
          </div>
          <p>活跃用户</p>
        </div>
      </li>
      <li>
        <div class="left_icon">
          <i class="el-icon-user-solid"></i>
        </div>
        <div class="right_content">
          <div class="title">
            累计支付买家数
          </div>
          <div class="num">
            {{ overViewResult.buyersCount }}
          </div>
          <p>活跃用户</p>
        </div>
      </li>
      <li>
        <div class="left_icon">
          <i class="el-icon-user-solid"></i>
        </div>
        <div class="right_content">
          <div class="title">
            累计成交总额(元)
          </div>
          <div class="num">
            {{ overViewResult.transactionValue }}
          </div>
          <p>活跃用户</p>
        </div>
      </li>
    </ul>
    <h2>优惠券类型</h2>
    <div class="underline"></div>
    <ul class="coupon_list">
      <li v-for="item in couponMsg" :key="item.couponSubType">
        <div class="type_title">{{msgs[item.couponSubType].title}}</div>
        <div class="type_intro">{{msgs[item.couponSubType].intro}}</div>
        <div class="type_num">{{item.couponCount}}张</div>
        <div class="type_btn">
          <el-button type="primary" @click="createCoupon(item.couponSubType)">立即创建</el-button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'OverView',
  components: {

  },
  props: {
    isChangeStore: Boolean
  },
  data() {
    this.msgs = {
      201: {title: '商品立减券', intro: '定向优惠券，买家购买指定商品，凭券满足条件抵扣现金'},
      202: {title: '店铺满减券', intro: '全店通用，买家购买全店商品，凭券满足条件抵扣现金'},
      203: {title: '直播优惠券', intro: '特色活动，买家在该商品参加直播时，可以在直播间领取该优惠券，凭券抵扣现金'},
      204: {title: '兑换券', intro: '定向优惠券，买家购买指定商品，凭券兑换商品'}
    };
    return {
      storeList: JSON.parse(localStorage.getItem('userInfo')).storeList,
      storeId: JSON.parse(localStorage.getItem('userInfo')).storeList[0].storeId,
      overViewResult: {
        buyersCount: 0, // 累计支付买家数
        orderCount: 0, // 订单总数
        transactionValue: 0 // 成交金额
      },
      couponMsg: []
    }
  },
  watch() {

  },
  created() {
    this.getResult()
    this.getSubTypeNum()
  },
  methods: {
    changeStore(val) {
      this.storeId = val
      this.getResult()
      this.getSubTypeNum()
    },
    // 立即创建
    createCoupon(type) {
      this.$router.push({
        path: '/nb/marketingTools/coupon/addAndEdit',
        query: {
          couponSubType: type,
          storeId: this.storeId,
          operateType: 'add'
        }
      })
    },

    // 获取概览数据
    getResult() {
      this.$axios(this.$api.coupon.over_view_result, {
        params: {
          storeid: this.storeId
        }
      }).then(res => {
        if (res.code === 0) {
          this.overViewResult = res.data
        }
      })
    },
    // 获取优惠券数量
    getSubTypeNum() {
      this.$axios(this.$api.coupon.sub_type_num, {
        params: {
          storeid: this.storeId
        }
      }).then(res => {
        if (res.code === 0) {
          this.couponMsg = res.data
          this.couponMsg = res.data.filter(item => item.couponSubType !== 203) // 隐藏直播优惠券
        }
      })
    }

  }

}
</script>

<style lang="scss" scoped>
.over_view {

    h2 {
        font-weight: 700;
    }
    .underline {
        height: 1px;
        background: #999;
        margin: 10px 0;
    }
    .result {
        margin: 20px 0 30px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
            width: 28%;
            padding: 20px;
            border: 1px solid rgba(233, 233, 233, 1);
            border-radius: 2px;
            display: inline-flex;
            justify-content: space-around;
            .left_icon {
                width: 10%;
            }
            .right_content {
                width: 80%;
                .title {
                    font-weight: 650;
                    font-size: 16px;

                }
                .num {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 24px;
                    margin: 20px 0 30px;
                }
                p {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 24px;
                    color: #999;
                }
            }
        }
    }
    @media screen and (max-width: 500px){
        .result {
            justify-content: center;
            li {
                width: 80%;
                margin-bottom: 10px;
            }
        }
    }

    .coupon_list {
        margin: 20px 0 30px;
        display: flex;
        justify-content: center;
        // flex-wrap: wrap;

        li {
            width: 30%;
            margin-right: 30px;
            padding: 20px;
            border: 1px solid #575757;
            border-radius: 10px;
            text-align: center;
            .type_title {
                font-size: 16px;
            }
            .type_intro{
                color: #AAAAAA;
                height: 60px;
                font-size: 14px;
                margin: 20px 0 30px;
                text-align: left;
            }
            .type_num {
                color: #999;
                margin-bottom: 20px;
            }
        }

    }

    @media screen and (max-width: 500px){
        .coupon_list {
            li {
                width: 80%;
                margin-bottom: 10px;
                margin-right: 0;
            }
        }

    }
}
</style>
