<template>
  <div class="coupon_content">
    <page-content>
      <el-tabs v-model="active_name">
        <el-tab-pane
          label="优惠券概览"
          name="overView"
        >
          <OverView v-if="active_name == 'overView'" />
        </el-tab-pane>
        <el-tab-pane
          label="优惠券管理"
          name="manage"
        >
          <CouponManage v-if="active_name == 'manage'" />
        </el-tab-pane>
        <el-tab-pane
          v-if="!from"
          label="数据效果"
          name="dataResult"
        >
          <DataResult v-if="active_name == 'dataResult'" />
        </el-tab-pane>
        <el-tab-pane
          v-if="!from"
          label="平台券"
          name="platform"
        >
          <Platform v-if="active_name == 'platform'" />
        </el-tab-pane>
      </el-tabs>
    </page-content>
  </div>
</template>

<script>
/**
 * 活动报名列表页面
 * 用途：用于供应商参加活动报名
 */
import DataResult from './components/dataResult'
import CouponManage from './components/manage'
import OverView from './components/overView'
import Platform from './components/Platform'
import { isApp } from '../../../../utils/isApp.js'

export default {
  name: 'Coupon',
  components: {
    DataResult,
    CouponManage,
    OverView,
    Platform
  },
  data() {
    return {
      active_name: 'overView',
      from: false
    }
  },
  created() {

  },
  mounted() {
    this.from = isApp()
    let navbar = document.getElementById('navbar')
    if (this.from && navbar) {
      navbar.style.opacity = '0'
    } else {
      navbar.style.opacity = '1'
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.coupon_content {
  background: #fff;
}
</style>

<style lang="scss">
.store_content {
  margin: 10px 0 30px;
  .store_name {
    color: red;
    margin-right: 10px;
  }
}
  @media screen and (max-width: 500px){
    .store_content {
      .store_name {
        margin-bottom: 10px;
        display: inline-block;
      }
    }

  }
 .goods_message {
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    .left {
      margin: 0 15px;
      img {
        width: 80px;
        height: 80px;
      }
    }
    .right {
      .title {
        font-weight: 700;
        margin: 10px 0;
      }
      .id {
        color: #999;
      }
    }
  }
</style>
