var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "coupon_content" },
    [
      _c(
        "page-content",
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.active_name,
                callback: function ($$v) {
                  _vm.active_name = $$v
                },
                expression: "active_name",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "优惠券概览", name: "overView" } },
                [_vm.active_name == "overView" ? _c("OverView") : _vm._e()],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "优惠券管理", name: "manage" } },
                [_vm.active_name == "manage" ? _c("CouponManage") : _vm._e()],
                1
              ),
              !_vm.from
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "数据效果", name: "dataResult" } },
                    [
                      _vm.active_name == "dataResult"
                        ? _c("DataResult")
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.from
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "平台券", name: "platform" } },
                    [_vm.active_name == "platform" ? _c("Platform") : _vm._e()],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }