var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _c(
      "div",
      { staticStyle: { height: "0px", width: "0px", overflow: "hidden" } },
      [
        _c("div", { ref: "downBox", staticClass: "down_box" }, [
          _c("div", { staticClass: "footer" }, [
            _vm.share.shareCouponInfo
              ? _c("div", { staticClass: "content" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.share.shareCouponInfo.title)),
                  ]),
                  _vm.share.shareCouponInfo.content
                    ? _c("div", { staticClass: "name" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.setText(_vm.share.shareCouponInfo.content)
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm.from === "wxApp"
              ? _c("img", {
                  ref: "codeRef",
                  staticClass: "image",
                  attrs: { src: _vm.share.wxminiImage, alt: "" },
                })
              : _vm._e(),
            _vm.from === "h5"
              ? _c("div", {
                  ref: "codeRef",
                  staticClass: "image",
                  attrs: { id: "qrCode" },
                })
              : _vm._e(),
          ]),
        ]),
      ]
    ),
    _c(
      "div",
      { staticStyle: { background: "#ccc", padding: "20px", width: "343px" } },
      [
        _c("img", {
          staticStyle: { width: "303px" },
          attrs: { src: _vm.imgUrl, alt: "" },
        }),
      ]
    ),
    _c(
      "div",
      [
        _c(
          "el-button",
          {
            attrs: { type: "text", loading: _vm.loading },
            on: {
              click: function ($event) {
                return _vm.onDown("image")
              },
            },
          },
          [_vm._v("下载海报")]
        ),
        _c(
          "el-button",
          {
            attrs: { type: "text", loading: _vm.loading },
            on: {
              click: function ($event) {
                return _vm.onDown("qrcode")
              },
            },
          },
          [_vm._v("下载二维码")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }