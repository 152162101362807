// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.47.0/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.store_content {\n  margin: 10px 0 30px;\n}\n.store_content .store_name {\n  color: red;\n  margin-right: 10px;\n}\n@media screen and (max-width: 500px) {\n.store_content .store_name {\n    margin-bottom: 10px;\n    display: inline-block;\n}\n}\n.goods_message {\n  display: -webkit-inline-box;\n  display: -ms-inline-flexbox;\n  display: inline-flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -ms-flex-pack: distribute;\n      justify-content: space-around;\n}\n.goods_message .left {\n  margin: 0 15px;\n}\n.goods_message .left img {\n  width: 80px;\n  height: 80px;\n}\n.goods_message .right .title {\n  font-weight: 700;\n  margin: 10px 0;\n}\n.goods_message .right .id {\n  color: #999;\n}", ""]);
// Exports
exports.locals = {
	"theme": "#30B08F",
	"menuBg": "#304156",
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
