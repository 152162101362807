<template>
  <el-dialog 
    title="推广" 
    :visible.sync="show" 
    :destroy-on-close="false"
    width="800px" 
    @open="putOpen" 
    @close="shareData = null"
    :before-close="handleClose">
    <el-tabs v-loading="loading" v-model="activeTab" tabPosition="left" >
      <el-tab-pane name="1" label="推广链接">
        <div style="display: flex; justify-content: space-between;">

          <DownBox v-if="activeTab == '1' && shareData && show" :share="shareData" from="h5" />

          <div style="margin: 0 10px; line-height: 18px">
            <div>分享h5链接:</div>
            <div v-if="shareData" style="margin-top: 5px">
              <a :href="shareData.shareUrl" target="_blank" style="text-decoration: underline; color: #409eff">{{shareData.shareUrl}}</a>
              <!-- <el-link :href="shareData.shareUrl" target="_blank" type="primary">{{shareData.shareUrl}}</el-link> -->
              <div style="margin-top: 10px">
                <el-button type="primary" size="mini"  @click="handleClipboard($event)">复制</el-button>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane name="2" label="微信小程序">

        <DownBox v-if="activeTab == '2' && shareData && show" :share="shareData" from="wxApp" />

      </el-tab-pane>
    </el-tabs>

    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose" size="small">取 消</el-button>
      <el-button type="primary" @click="handleClose" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import DownBox from './DownBox';
import clipboard from '@/utils/clipboard'
export default {
  name: '',
  components: {
    DownBox
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    },
    couponType: {
      type: String,
      default: ''
    },
    tokenType: {
      type: String,
      default: '2'
    }
  },
  data () {
    return {
      activeTab: '1',
      shareData: null,
      loading: false,
    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {
    
  },
  methods: {
    putOpen() {
      this.loading = true;
      this.$axios.post(this.$api.share.share, {
        "itemId": this.id,
        "itemType": 19,
        "recordId": 0,
        "shareType": this.couponType,
        "tokenType": this.tokenType, //2运营后台, 1商家后台
      })
      .then((res) => {
        if (res.code === 0) {
          this.shareData = res.data;
        }
      }).finally(()=>{
        this.loading = false;
      })
    },
    handleClose() {
      this.$emit('update:show', false);
    },
    handleClipboard(event) {
      clipboard(this.shareData.shareUrl, event)
    },
  }
}
</script>

<style scoped lang="scss">

</style>