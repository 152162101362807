<template>
  <div class="">
    <div style="height: 0px;width: 0px;overflow: hidden;">
      <div class="down_box" ref="downBox">
        <div class="footer">
          <div class="content" v-if="share.shareCouponInfo">
            <div class="title">{{share.shareCouponInfo.title}}</div>
            <div class="name" v-if="share.shareCouponInfo.content">
              {{setText(share.shareCouponInfo.content)}}
            </div>
          </div>
          <img v-if="from === 'wxApp'" ref="codeRef" class="image" :src="share.wxminiImage" alt="">
          
          <div v-if="from === 'h5'" class="image" id="qrCode" ref="codeRef"></div>
        </div>
      </div>
    </div>

    <div style="background: #ccc; padding: 20px; width: 343px">
      <img style="width: 303px" :src="imgUrl" alt="">
    </div>
    
    <div>
      <el-button type="text" :loading="loading" @click="onDown('image')">下载海报</el-button>
      <el-button type="text" :loading="loading" @click="onDown('qrcode')">下载二维码</el-button>
    </div>
    
  </div>
</template>

<script>
import html2canvas from "html2canvas"
export default {
  name: '',
  components: {},
  props: {
    from: {
      type: String,
      default: 'wxApp'
    },
    share: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      loading: false,
      imgUrl: '',//海报图片
      codeImage: '',//二维码
      qrcode: null,
    }
  },
  computed: {},
  watch: {

  },
  created () {},
  mounted () {
    if(this.from === 'wxApp') {
      this.getImage('downBox');
      this.getImage('codeRef');
    }else if(this.from === 'h5'){
      this.qrcode = new QRCode(document.getElementById('qrCode'),{
          width: 152 * 1.5,
          height: 152 * 1.5,
      });
      this.qrcode.makeCode(this.share.shareUrl);
      
      this.getImage('downBox');
      this.getImage('codeRef');
    }

    
  },
  methods: {
    onDown(type) {
      if(type === 'image') {
        this.downloadImg(this.imgUrl, '推广海报');
      }else {
        this.downloadImg(this.codeImage, '二维码');
      }
    },
    getImage(ref) {
      let imgDom = this.$refs[ref]
      // 解决转换出来的图片的清晰度问题
      // 手动创建一个 canvas 标签
      const canvas = document.createElement("canvas")
      // 获取父级的宽高
      const width = parseInt(window.getComputedStyle(imgDom).width)
      const height = parseInt(window.getComputedStyle(imgDom).height)
      // 定义放大倍数，可支持小数
      let scale = this.getPixelRatio()
      // 设定 canvas 元素属性宽高为 DOM 节点宽高 * 像素比
      canvas.width = width * scale
      canvas.height = height * scale
      // 设定 canvas css宽高为 DOM 节点宽高
      canvas.style.width = width + 'px'
      canvas.style.height = height + 'px'
      // 获取content,设置scale
      const context = canvas.getContext("2d")
      // 【重要】关闭抗锯齿

      context.mozImageSmoothingEnabled = false;

      context.webkitImageSmoothingEnabled = false;

      context.msImageSmoothingEnabled = false;

// context.imageSmoothingEnabled = false;

      // context.scale(scale, scale)
      // 拿到目标dom调用一下html2canvas方法就能生成canvas对象了
      // 获取要转换的元素
      
      html2canvas(imgDom, {
        backgroundColor: null,
        canvas: canvas,
        scale: scale,
        useCORS: true, // 开启跨域设置，需要后台设置cors
      }).then((canvas) => {
        // toDataURL函数生成img标签的可用数据  图片格式转成 base64
        let dataURL = canvas.toDataURL("image/png")
        
        if(ref === 'downBox') {
          this.imgUrl = dataURL;
        }else {
          this.codeImage = dataURL;
        }
        
      }).finally(()=>{
        
      })
    },
    downloadImg(url, name) {
      //下载图片
      // 创建隐藏的可下载链接
      var eleLink = document.createElement('a')
      eleLink.download = name + '.jpg'
      eleLink.style.display = 'none'

      // 如果是PNG图片，则canvas.toDataURL('image/png')
      eleLink.href = url
      // 触发点击
      document.body.appendChild(eleLink)
      eleLink.click()
      // 然后移除
      document.body.removeChild(eleLink)
    },
    // 动态获取设备的像素比，从而正确放大 canvas
    getPixelRatio() {
      if (window.devicePixelRatio && window.devicePixelRatio > 1) {
        return window.devicePixelRatio
      }
      return 1
    },
    setText(str) {
      return str.length > 10 ? str.substring(0,10) + '...' : str
      // let len = 0, newStr = '';
      // for(let i = 0; i < str.length; i++) {
      //   if(len >= 20) {
      //     return newStr
      //   }
      //   let c = str.charCodeAt(i);
      //   if(c>255) {
      //     len += 2;
      //   }else {
      //     len += 1
      //   }
      //   newStr += str[i];
      // }
    }
  }
}
</script>

<style scoped lang="scss">
.down_box {
  // 
  position: relative;
  // top: -9999px;
  width: calc(606px * 1.5);
  height: calc(800px * 1.5);
  background: url('https://wsnbh.oss-cn-hangzhou.aliyuncs.com/default/1663586345566.png');
  background-size: 100% 100%;
  // border-radius: 20px;
  // overflow: hidden;
  .footer {
    position: absolute;
    height: calc(192px * 1.5);
    bottom: 0;
    left: 0;
    width: 100%;
    padding: calc(20px * 1.5) calc(28px * 1.5) calc(20px * 1.5) calc(40px * 1.5);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    border-radius: 0 0 calc(20px * 1.5) calc(20px * 1.5);
    .content {
      width: calc(336px * 1.5);
      
      .title {
        font-size: calc(32px * 1.5);
        line-height: calc(44px * 1.5);
        color: #FF0000;
        font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
        margin-bottom: calc(10px * 1.5);
        overflow:hidden;
white-space:nowrap;
text-overflow:ellipsis;
      }
      .name {
        font-size: calc(30px * 1.5);
        line-height: calc(42px * 1.5);
        color: #999999;
        font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
        margin-top: calc(10px * 1.5);
        overflow:hidden;
white-space:nowrap;
text-overflow:ellipsis;
      }
    }
    > .image {
      background: #fff;
      width: calc(152px * 1.5);
      height: calc(152px * 1.5);
    }
  }
}
</style>