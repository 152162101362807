<template>
  <div class="coupon_content">
    <div
      v-if="storeList && storeList.length > 0"
      class="store_content"
    >
      <span class="store_name">请先选择店铺 : </span>
      <el-select
        v-model="storeId"
        size="mini"
        placeholder="请先选择店铺"
        @change="changeStore"
      >
        <el-option
          v-for="item in storeList"
          :key="item.storeId"
          :label="item.storeName"
          :value="item.storeId"
        />
      </el-select>
    </div>
    <search-panel
      flex
      show-btn
      @getList="getListChange"
    >
      <div>
        <p>优惠券ID:</p>
        <el-input
          v-model.trim="searchParams.couponPackageId"
          placeholder="请输入优惠券ID"
          size="mini"
          clearable
        />
      </div>
      <div>
        <p>活动状态:</p>
        <el-select
          v-model="searchParams.status"
          size="mini"
          placeholder="请选择状态"
          clearable
        >
          <el-option
            :value="1"
            label="上架"
          />
          <el-option
            :value="2"
            label="下架"
          />
        </el-select>
      </div>
    </search-panel>
    <el-table
      v-loading="loading"
      :data="tableData"
      border
      style="width: 100%"
      element-loading-text="拼命加载中"
    >
      <el-table-column
        prop="couponPackageId"
        label="优惠券包ID"
        min-width="110"
      />
      <el-table-column
        prop="couponPackageName"
        label="券名称"
        min-width="110"
      />
      <el-table-column
        label="发放方式"
        min-width="110"
      >
        <template slot-scope="{row}">
          <template v-if="row.couponGiveOutType == 1">
            用户领取
          </template>
          <template v-if="row.couponGiveOutType == 21">
            动发放
          </template>
          <template v-if="row.couponGiveOutType == 22">
            指定用户发放
          </template>
        </template>
      </el-table-column>
      <el-table-column
        prop="couponCount"
        label="优惠券数量（张/份）"
        min-width="110"
      />
      <el-table-column
        label="券包状态"
        min-width="100"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.couponPackageStatus == 1">
            上架
          </div>
          <div v-if="scope.row.couponPackageStatus == 2">
            下架
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="创建时间"
        min-width="160"
      >
        <template slot-scope="scope">
          {{ dayFormat(scope.row.createTime) }}
        </template>
      </el-table-column>
      <el-table-column
        label="发放时间"
        min-width="160"
      >
        <template slot-scope="scope">
          {{ dayFormat(scope.row.giveOutStartTime) }} - {{ dayFormat(scope.row.giveOutEndTime) }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        min-width="80"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="mini"
            @click="onShowPop(scope.row)"
          >
            推广
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      small
      :current-page="pagination.page"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pagination.page_count"
      layout="prev, pager, next"
      :total="pagination.item_total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />

    <!-- 推广 -->
    <Pop
      :id="popId"
      :show.sync="showPop"
      coupon-type="1"
      token-type="1"
    />
  </div>
</template>

<script>

import dayjs from 'dayjs';
import Pop from './popup/pop';

export default {
  name: 'coupon-manage',
  components: {
    Pop,
  },
  data() {
    return {
      storeList: JSON.parse(localStorage.getItem('userInfo')).storeList,
      storeId: JSON.parse(localStorage.getItem('userInfo')).storeList[0].storeId,
      searchParams: {
        couponPackageId: '',
        status: '',
      },
      subCouponList: [],
      loading: false,
      tableData: [],
      pagination: {
        page: 1,
        page_total: 0,
        item_total: 0,
        page_count: 10,
      },
      currentNum: 0,
      addNum: 0,
      copy_url: window.location.href,

      showPop: false, // 投放推广
      popId: '',
    };
  },
  computed: {
    sum() {
      return parseFloat(this.currentNum) + this.addNum;
    },
  },
  created() {
    this.getList();
    // this.getSubCouponList()
  },
  methods: {
    // 切换店铺
    changeStore(val) {
      this.storeId = val;
      this.getList();
    },
    // 分页控制
    handleSizeChange(val) {
      this.pagination.page_count = val;
      this.pagination.page = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pagination.page = val;
      this.getList();
    },
    getListChange() {
      this.pagination.page = 1;
      this.getList();
    },
    getList() { //
      let _this = this;
      _this.loading = true;
      let pars = {
        currentPage: _this.pagination.page,
        pageSize: _this.pagination.page_count,
        storeId: this.storeId,
      };
      if (this.searchParams.couponPackageId) {
        pars.couponPackageId = this.searchParams.couponPackageId;
      }
      pars.status = this.searchParams.status;
      _this.$axios({
        method: 'post',
        url: _this.$api.coupon.search_coupon_package,
        data: pars,
      }).then((res) => {
        if (res.code === 0) {
          const {
            size, total, current, pages,
          } = res.data;
          _this.pagination = {
            page: Number(current),
            page_count: Number(size),
            page_total: Number(pages),
            item_total: Number(total),
          };
          _this.tableData = res.data.records;
        } else {
          _this.tableData = [];
          _this.pagination.item_total = 0;
        }
        _this.loading = false;
      }).catch((error) => {
        console.log(error);
      });
    },

    dayFormat(val) {
      return dayjs(parseInt(val)).format('YYYY/MM/DD HH:mm:ss');
    },
    onShowPop(row) {
      this.showPop = true;
      this.popId = row.couponPackageId;
    },
  },
};
</script>

<style lang="scss" scoped>
.coupon_content {
    .margin {
        margin: 16px 0;
    }
    .warning {
        color: #999;
        text-align: right;
    }
}
</style>
