<template>
  <div class="coupon_content">
    <div
      v-if="storeList && storeList.length > 0"
      class="store_content"
    >
      <span class="store_name">请先选择店铺 : </span>
      <el-select
        v-model="storeId"
        size="mini"
        placeholder="请先选择店铺"
        @change="changeStore"
      >
        <el-option
          v-for="item in storeList"
          :key="item.storeId"
          :label="item.storeName"
          :value="item.storeId"
        >
        </el-option>
      </el-select>
    </div>
    <search-panel
      flex
      show-btn
      @getList="getListChange"
    >
      <div>
        <p>优惠券名称/ID:</p>
        <el-input
          v-model.trim="searchParams.couponName"
          placeholder="请输入优惠券名称/ID"
          size="mini"
          clearable
        />
      </div>
      <div>
        <p>优惠券类型:</p>
        <el-select
          v-model="searchParams.couponSubType"
          placeholder="请选择优惠券类型"
          size="mini"
          clearable
        >
          <el-option
            v-for="item in subCouponList"
            :key="item.typeId"
            :label="item.typeName"
            :value="item.typeId"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        <p>活动状态:</p>
        <el-select
          v-model="searchParams.activiteState"
          size="mini"
          placeholder="请选择状态"
          clearable
        >
          <el-option
            :value="0"
            label="未开始"
          ></el-option>
          <el-option
            :value="1"
            label="生效中"
          ></el-option>
          <el-option
            :value="2"
            label="已结束"
          ></el-option>
        </el-select>
      </div>
    </search-panel>
    <el-table
      v-loading="loading"
      :data="tableData"
      border
      style="width: 100%"
      element-loading-text="拼命加载中"
    >
      <el-table-column
        prop="couponId"
        label="优惠券ID"
        min-width="110"
      />
      <el-table-column
        prop="couponName"
        label="券名称"
        min-width="110"
      />
      <el-table-column
        prop="discountsValue"
        label="面额/折扣"
        width="110"
      />
      <el-table-column
        label="领用条件"
        min-width="110"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.couponSubType === 204">/</div>
          <div v-else>
            {{ discountsType && discountsType[scope.row.discountsType] }}
            {{ discountsConditionType && discountsConditionType[scope.row.discountsConditionType] }}
            {{ scope.row.discountsCondition }} 元可用
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="couponTotalCount"
        label="发放数量"
        width="110"
      />
      <el-table-column
        label="有效时间"
        min-width="160"
      >
        <template slot-scope="scope">
          {{ dayFormat(scope.row.validStartTime) }} - {{ dayFormat(scope.row.validEndTime) }}
        </template>
      </el-table-column>
      <el-table-column
        label="活动状态"
        min-width="100"
      >
        <template slot-scope="scope">
          {{ comState(scope.row.activiteState) }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        min-width="180"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="mini"
            @click="toEdit(scope.row, 'show')"
          >
            查看
          </el-button>
          <el-divider direction="vertical"></el-divider>
          <el-button
            :disabled="scope.row.activiteState !== 0"
            type="text"
            size="mini"
            @click="toEdit(scope.row, 'edit')"
          >
            编辑
          </el-button>
          <el-divider direction="vertical"></el-divider>
          <el-button
            :disabled="scope.row.activiteState === 2 || scope.row.activiteState === 3"
            type="text"
            size="mini"
            @click="setCouponStatus(scope.row.couponId, 1)"
          >
            下架
          </el-button>
          <el-divider direction="vertical"></el-divider>
          <el-button
            :disabled="scope.row.activiteState !== 3"
            type="text"
            size="mini"
            @click="setCouponStatus(scope.row.couponId, 0)"
          >
            上架
          </el-button>
          <el-divider direction="vertical"></el-divider>
          <template v-if="scope.row.couponSubType !== 204">
            <el-button
              :disabled="scope.row.activiteState === 2"
              type="text"
              size="mini"
              @click="toAddNum(scope.row)"
            >
              增发
            </el-button>
            <el-divider direction="vertical"></el-divider>
            <!-- <el-button
              type="text"
              size="mini"
              class="btn"
              :data-clipboard-text="copy_url+'?couponId='+scope.row.couponId"
              @click="copyLink"
            >
              复制链接
            </el-button> -->
            <el-button
              type="text"
              size="mini"
              @click="onShowPop(scope.row)"
            >
              推广
            </el-button>
          </template>
          <el-button
            v-else
            type="text"
            size="mini"
            @click="downloadRedemptionCode(scope.row)"
          >
            下载兑换码
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      small
      :current-page="pagination.page"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pagination.page_count"
      layout="prev, pager, next"
      :total="pagination.item_total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />

    <!-- 增加优惠券发行量弹框 -->
    <el-dialog
      v-if="circulationVisible"
      title="增加优惠券发行量"
      :visible.sync="circulationVisible"
      width="30%"
    >
      <div class="margin">
        当前发行量:   {{ currentNum }} 张
      </div>
      <span>新增发行量:  </span>
      <el-input-number
        v-model="addNum"
        :min="0"
        size="mini"
        style="width: 200px"
      /> 张
      <div class="margin">
        增加后发行量:   {{ sum }} 张
      </div>
      <div class="warning margin">
        总发行量不可超过1000000
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          size="mini"
          @click="onSureAddCount"
        >确 定</el-button>
        <el-button
          size="mini"
          @click="circulationVisible = false"
        >取 消</el-button>
      </span>
    </el-dialog>
    <!-- 推广 -->
    <Pop :show.sync="showPop" :id="popId" couponType="2" tokenType="1" />
  </div>
</template>

<script>

import qs from 'qs'
import Clipboard from 'clipboard'
import dayjs from 'dayjs'
import { discountsType, discountsConditionType } from '../../config'
import Pop from './popup/pop'
import { downloadFile } from '@/utils/download';

export default {
  name: 'CouponManage',
  components: {
    Pop
  },
  data() {
    return {
      storeList: JSON.parse(localStorage.getItem('userInfo')).storeList,
      storeId: JSON.parse(localStorage.getItem('userInfo')).storeList[0].storeId,
      searchParams: {
        couponName: '',
        couponSubType: '',
        activiteState: ''
      },
      discountsType,
      discountsConditionType,
      subCouponList: [],
      loading: false,
      tableData: [],
      pagination: {
        page: 1,
        page_total: 0,
        item_total: 0,
        page_count: 10
      },
      circulationVisible: false,
      currentNum: 0,
      addNum: 0,
      couponId: '',
      copy_url: window.location.href,

      showPop: false, //投放推广
      popId: '',
    }
  },
  computed: {
    sum: function() {
      return parseFloat(this.currentNum) + this.addNum
    }
  },
  created() {
    this.getList()
    this.getSubCouponList()
  },
  methods: {
    // 下载兑换码
    downloadRedemptionCode(row) {
      this.$axios.post(this.$api.coupon.download, {couponId: row.couponId}, {responseType: 'blob'})
      .then((res) => {
        downloadFile(res, `优惠券-id${row.couponId}兑换码.xls`);
      })
    },
    //上下架操作
    setCouponStatus(couponId, status) {
      let message = status === 0 ? '是否确认上架优惠券？' : '是否确认下架优惠券？'
      this.$confirm(message, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios.get(this.$api.coupon.operate_coupon, {
            params: {
              couponId: couponId,
              couponStatus: status
            }
          })
          .then((res) => {
            if (res.code === 0) {
              this.$message({
                message: '操作成功！',
                type: 'success'
              })
              this.getList()
            }
          })
        })
    },
    // 切换店铺
    changeStore(val) {
      this.storeId = val
      this.getList()
    },
    comState(val) {
      return val == 0 ? '未开始' : val == 1 ? '生效中' : val == 2 ? '已结束 (已失效)' : val == 3 ? '已结束 (已下架)' : ''
    },
    // 分页控制
    handleSizeChange(val) {
      this.pagination.page_count = val
      this.pagination.page = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pagination.page = val
      this.getList()
    },
    // 获取优惠券下拉列表
    getSubCouponList() {
      this.$axios.post(
        this.$api.coupon.sub_coupon_list,
        qs.stringify({
          pid: 2
        })
      ).then((res) => {
        if (res.code === 0) {
          this.subCouponList = res.data
        }
      }).catch((error) => {
        console.log(error)
      })
    },

    toEdit(row, type) {
      const { couponId, couponSubType } = row
      this.$router.push({
        path: '/nb/marketingTools/coupon/addAndEdit',
        query: {
          couponSubType: couponSubType,
          id: couponId,
          storeId: this.storeId,
          operateType: type
        }
      })
    },
    // toFinish(id) {
    //   let that = this
    //   that.$confirm('确认结束后将停止发券，已领取的优惠券可继续使用', '确定要结束发券吗？', {
    //     cancelButtonText: '取消',
    //     confirmButtonText: '确定',
    //     type: 'warning'
    //   }).then(() => {
    //     that.$axios(that.$api.coupon.end_coupon, {
    //       params: {
    //         couponid: id
    //       }
    //     }).then(res => {
    //       if (res.code === 0) {
    //         that.getList()
    //         that.$message({
    //           type: 'success',
    //           message: '结束成功'
    //         })
    //       }
    //     })
    //   }).catch(() => {
    //     that.$message({
    //       type: 'info',
    //       message: '已取消结束'
    //     })
    //   })
    // },
    toAddNum(row) {
      this.circulationVisible = true
      this.currentNum = row.couponCount
      this.couponId = row.couponId
    },
    onSureAddCount() {
      if (this.sum > 1000000) {
        return this.$message.warning('总发行量超过1000000')
      } else {
        this.circulationVisible = false
        this.$axios(this.$api.coupon.add_coupon_count, {
          params: {
            count: this.addNum,
            couponid: this.couponId
          }
        }).then(res => {
          if (res.code === 0) {
            this.$message.success('增加成功')
            this.currentNum = 0
            this.addNum = 0
            this.getList()
          }
        })
      }
    },
    copyLink() {
      const clipboard = new Clipboard('.btn')
      clipboard.on('success', e => {
        this.$message.success('复制成功')
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        this.$message.error('该浏览器不支持自动复制')
        clipboard.destroy()
      })
      console.log('复制')
    },

    getListChange() {
      this.pagination.page = 1
      this.getList()
    },
    getList() { //
      let _this = this
      _this.loading = true
      _this.$axios({
        method: 'post',
        url: _this.$api.coupon.template_list,
        data: {
          ...this.searchParams,
          currentPage: _this.pagination.page,
          pageSize: _this.pagination.page_count,
          storeId: this.storeId
        }
      }).then(function(res) {
        if (res.code === 0) {
          const { size, total, current, pages } = res.data
          _this.pagination = {
            page: Number(current),
            page_count: Number(size),
            page_total: Number(pages),
            item_total: Number(total)
          }

          res.data.records.forEach(item => {
            if (item.couponSubType === 204) item.discountsValue = '/';
          });

          _this.tableData = res.data.records
        } else {
          _this.tableData = []
          _this.pagination.item_total = 0
        }
        _this.loading = false
      }).catch(function(error) {
        console.log(error)
      })
    },

    dayFormat(val) {
      return dayjs(parseInt(val)).format('YYYY/MM/DD HH:mm:ss')
    },
    onShowPop(row) {
      this.showPop = true;
      this.popId = row.couponId;
    }
  }
}
</script>

<style lang="scss" scoped>
.coupon_content {
    .margin {
        margin: 16px 0;
    }
    .warning {
        color: #999;
        text-align: right;
    }
}
</style>
