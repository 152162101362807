<template>
  <div
    id="couponEchartContent"
    class="echart_content"
  >
  </div>
</template>

<script>
import echarts from 'echarts'
import { getDay } from '@/utils/getDay'

export default {
  name: 'DataEchart',
  props: {
    daySize: String,
    dataArr: Array
  },
  data() {
    return {

    }
  },
  watch: {
    daySize: {
      immediate: true, // 这句重要
      handler(newVal) {
        this.daySize = newVal
        if (document.getElementById('couponEchartContent')) {
          this.show()
        }
      }
    },
    dataArr: {
      immediate: true, // 这句重要
      handler(newVal) {
        this.dataArr = newVal
        if (document.getElementById('couponEchartContent')) {
          this.show()
        }
      }
    }
  },
  mounted() {
    if (document.getElementById('couponEchartContent')) {
      this.show()
    }
  },
  methods: {
    show() {
      let chartDom = document.getElementById('couponEchartContent')
      let myChart = echarts.init(chartDom)
      let option
      let dayArr = [getDay(-7), getDay(-6), getDay(-5), getDay(-4), getDay(-3), getDay(-2), getDay(-1)]
      let dayArrThree = [getDay(-30), getDay(-29), getDay(-28), getDay(-27), getDay(-26), getDay(-25), getDay(-24), getDay(-23), getDay(-22), getDay(-21), getDay(-20), getDay(-19), getDay(-18), getDay(-17), getDay(-16), getDay(-15), getDay(14), getDay(-13), getDay(-12), getDay(-11), getDay(-10), getDay(-9), getDay(-8), getDay(-7), getDay(-6), getDay(-5), getDay(-4), getDay(-3), getDay(-2), getDay(-1)]
      console.log(' this.dataArr:', this.dataArr)
      option = {
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.daySize == '近7天' ? dayArr : dayArrThree
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          data: this.dataArr,
          type: 'line',
          areaStyle: {}
        }]
      }

      option && myChart.setOption(option)
    }

  }
}
</script>

<style lang="scss" scoped>
    .echart_content {
        width: 100%;
        height: 500px;
    }
</style>
