export function isApp() {
  let u = navigator.userAgent
  let isapp = false
  if (u.indexOf('#') != -1) {
    let str = u.substring(u.indexOf('#') + 1)
    let arr = str.split('/')
    let os = arr[0]
    if (os == 'Nbh' || os == 'Nbgys') {
      isapp = true
    }
  }
  return isapp
}
