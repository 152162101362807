var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "推广",
        visible: _vm.show,
        "destroy-on-close": false,
        width: "800px",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.putOpen,
        close: function ($event) {
          _vm.shareData = null
        },
      },
    },
    [
      _c(
        "el-tabs",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { tabPosition: "left" },
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        [
          _c("el-tab-pane", { attrs: { name: "1", label: "推广链接" } }, [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "space-between",
                },
              },
              [
                _vm.activeTab == "1" && _vm.shareData && _vm.show
                  ? _c("DownBox", {
                      attrs: { share: _vm.shareData, from: "h5" },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  { staticStyle: { margin: "0 10px", "line-height": "18px" } },
                  [
                    _c("div", [_vm._v("分享h5链接:")]),
                    _vm.shareData
                      ? _c("div", { staticStyle: { "margin-top": "5px" } }, [
                          _c(
                            "a",
                            {
                              staticStyle: {
                                "text-decoration": "underline",
                                color: "#409eff",
                              },
                              attrs: {
                                href: _vm.shareData.shareUrl,
                                target: "_blank",
                              },
                            },
                            [_vm._v(_vm._s(_vm.shareData.shareUrl))]
                          ),
                          _c(
                            "div",
                            { staticStyle: { "margin-top": "10px" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleClipboard($event)
                                    },
                                  },
                                },
                                [_vm._v("复制")]
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c(
            "el-tab-pane",
            { attrs: { name: "2", label: "微信小程序" } },
            [
              _vm.activeTab == "2" && _vm.shareData && _vm.show
                ? _c("DownBox", {
                    attrs: { share: _vm.shareData, from: "wxApp" },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleClose } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.handleClose },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }