<template>
  <div class="receive_content">
    <page-content>
      <div
        v-if="storeList && storeList.length > 0"
        class="store_content"
      >
        <span class="store_name">请先选择店铺 : </span>
        <el-select
          v-model="storeId"
          size="mini"
          placeholder="请先选择店铺"
          @change="changeStore"
        >
          <el-option
            v-for="item in storeList"
            :key="item.storeId"
            :label="item.storeName"
            :value="item.storeId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="data_collection">
        数据总览
        <span v-if="daySize == '近30天'">统计时间: {{ getDay(-30) }} 至 {{ getDay(0) }}</span>
        <span v-if="daySize == '近7天'">统计时间: {{ getDay(-6) }} 至 {{ getDay(0) }}</span>
      </div>
      <div class="coupon_name">
        <div class="right">
          <el-radio-group
            v-model="daySize"
            size="mini"
            @change="onChangeDay"
          >
            <el-radio-button label="近7天"></el-radio-button>
            <el-radio-button label="近30天"></el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <el-tabs
        v-model="tabName"
        type="border-card"
      >
        <el-tab-pane
          label="发行量（张）"
          name="couponIssueCount"
          :lazy="true"
        >
          <data-echart
            v-if="tabName == 'couponIssueCount' && isSucess"
            :day-size="daySize"
            :data-arr="couponIssueCount"
          />
        </el-tab-pane>
        <el-tab-pane
          label="领取量（张）"
          name="couponReceiveCount"
          :lazy="true"
        >
          <data-echart
            v-if="tabName == 'couponReceiveCount' && isSucess"
            :day-size="daySize"
            :data-arr="couponReceiveCount"
          />
        </el-tab-pane>
        <el-tab-pane
          label="使用量（张）"
          name="couponUseCount"
          :lazy="true"
        >
          <data-echart
            v-if="tabName == 'couponUseCount' && isSucess"
            :day-size="daySize"
            :data-arr="couponUseCount"
          />
        </el-tab-pane>
        <el-tab-pane
          label="券使用率"
          name="couponUseRate"
          :lazy="true"
        >
          <data-echart
            v-if="tabName == 'couponUseRate' && isSucess"
            :day-size="daySize"
            :data-arr="couponUseRate"
          />
        </el-tab-pane>
        <el-tab-pane
          label="订单覆盖率"
          name="couponOrderRate"
          :lazy="true"
        >
          <data-echart
            v-if="tabName == 'couponOrderRate' && isSucess"
            :day-size="daySize"
            :data-arr="couponOrderRate"
          />
        </el-tab-pane>
        <el-tab-pane
          label="券消耗金额（元）"
          name="couponUseMoney"
          :lazy="true"
        >
          <data-echart
            v-if="tabName == 'couponUseMoney' && isSucess"
            :day-size="daySize"
            :data-arr="couponUseMoney"
          />
        </el-tab-pane>
        <el-tab-pane
          label="券拉动店铺GMV（元）"
          name="couponCouponGmv"
          :lazy="true"
        >
          <data-echart
            v-if="tabName == 'couponCouponGmv' && isSucess"
            :day-size="daySize"
            :data-arr="couponCouponGmv"
          />
        </el-tab-pane>
      </el-tabs>
    </page-content>
  </div>
</template>

<script>
import echarts from 'echarts'
import dataEchart from './dataEchart'
import { getDay } from '@/utils/getDay'

export default {
  name: 'DataResult',
  components: {
    dataEchart
  },
  data() {
    return {
      storeList: JSON.parse(localStorage.getItem('userInfo')).storeList,
      storeId: JSON.parse(localStorage.getItem('userInfo')).storeList[0].storeId,
      tabName: 'couponIssueCount',
      daySize: '近7天',
      isSucess: false,
      couponIssueCount: [], // 发行量
      couponReceiveCount: [], // 领取量
      couponUseCount: [], // 使用量
      couponUseRate: [], // 券使用率
      couponOrderRate: [], // 订单使用率
      couponUseMoney: [], // 券消耗金额
      couponCouponGmv: [], // 券拉动店铺GMV(元)
      couponType: ''
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getDay(val) {
      return getDay(val)
    },
    // 切换店铺
    changeStore(val) {
      this.storeId = val
      this.getData()
    },
    onChangeDay(val) {
      this.getData()
      this.daySize = val
    },
    getData() {
      let couponIssueCount = []
      let couponReceiveCount = []
      let couponUseCount = []
      let couponUseRate = []
      let couponOrderRate = []
      let couponUseMoney = []
      let couponCouponGmv = []
      this.$axios(this.$api.coupon.get_all_coupon_data, {
        params: {
          daySize: this.daySize == '近7天' ? 7 : 30,
          storeid: this.storeId
        }
      }).then(res => {
        if (res.code === 0) {
          let result = res.data
          result && result.length > 0 && result.map(item => {
            couponIssueCount.push(item.couponIssueCount)
            couponReceiveCount.push(item.couponReceiveCount)
            couponUseCount.push(item.couponUseCount)
            couponUseRate.push(item.couponUseRate)
            couponOrderRate.push(item.couponOrderRate)
            couponUseMoney.push(item.couponUseMoney)
            couponCouponGmv.push(item.couponCouponGmv)
            this.couponType = item.couponType
            this.isSucess = true
          })
          this.couponIssueCount = couponIssueCount
          this.couponReceiveCount = couponReceiveCount
          this.couponUseCount = couponUseCount
          this.couponUseRate = couponUseRate
          this.couponOrderRate = couponOrderRate
          this.couponUseMoney = couponUseMoney
          this.couponCouponGmv = couponCouponGmv
        }
      })
    }

  }
}
</script>

<style lang="scss" scoped>
.receive_content {
    .data_collection {
        font-size: 16px;
        span {
            font-size: 12px;
            color: #999;
        }
    }
    div {
        margin-bottom: 20px;
    }
    .coupon_name {
        position: relative;
        height: 60px;
        font-size: 14px;
        .right {
            position: absolute;
            right: 0;
            top: 0;
        }
    }

}
</style>
